body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav{
  height: 200px;
  background-color: rgba(114, 174, 218, 0.9);
}

.oglebayNav {
  background-color: rgba(128,168,139,0.9);
}

.oglebayNav ul li{
  font-size: 2.2em;
}

.navLogo{
  background-image: url("./tiltedOHandLogo.png");
  background-size:contain;
  background-repeat: no-repeat;
  width: 170px;
  height: 80px;
  padding:10px;
}

.homeLogo{
  background-image: url("./hpLogo-01.png");
  background-size: contain;
  background-repeat: no-repeat;
  width:200px;
  height: 70px;
  margin-left: 10px;
  margin-top: 15px;
}

.welcomeDiv{
  display: flex;
  justify-content: center;
}

.welcomeDiv h2{
  margin-right: 10px;
}

nav ul {
  display: flex;
  text-decoration: none;
  justify-content: space-evenly;
  list-style: none;
  width: 85%;
  margin: 0 auto;
  padding-top: 50px;
  padding-inline-start: 0px;
}

nav ul li{
  font-size: 3em;
  text-decoration: none;
}

nav ul li:hover a{
  text-shadow: 0px 0px 30px white, 0px 0px 30px white, 0px 0px 30px white;  
}

nav ul li a{
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px;
  text-decoration: none;
  color: black;
  display: inline-block;
}

.backgroundImg{
  background-color: #81b58b;
  background-image: url("../images/HomePagePhoto2.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 1100px;
  margin: 0 auto;
}

.oglebayBackground{
  background-color: #81b58b;
  background-image: url("../images/ogbBackground.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 1100px;
  margin: 0 auto;
}

.OgbBackground{
  background-color: #81b58b;
  height: 1400px;
  background-image: url("../images/OgbBack3.jpg");
  background-size: cover;
}

.homePageDiv{
  padding-bottom: 50px;
}

.hpContent{
  width: 550px;
  background-color: rgba(114, 174, 218, 0.9);
  margin: 0 auto;
  margin-top: 75px;
  font-size: 1.5em;
  padding: 15px;
  line-height: 1.5em;
}

.hpContent h2{
  text-align: center;
}

.hpContent h3{
  text-align: center;
}

.infoPageDiv{
  background-color: white;
  width: 55%;
  margin: auto;
  height: 100%;
}

/* .tierImage{
  height: 1400px;
  background-image: url("../images/courses612.png");
  background-size:cover;
} */

.coursesImg{
  width:80%;
  display: block;
  margin: 0 auto;
  background-color: white;
  padding:20px;
}

.coursesContent{
  max-width: 80%;
  margin: 0 auto;
  background-color: rgb(235, 245, 242, 0.85);
  padding: 0px;
  height: 100%;
  min-height: 860px;
}

.courseHeadText{
  width: 75%;
  margin: auto;
  padding: 20px 0px 20px 0px;
  font-size: 1.2em;
}

.infoHeader{
  font-size: 4em;
  text-align: center;
  border-bottom: 2px solid red;
  margin: auto;
  width: 75%;
}

table{
  width: 75%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  border-spacing: 0px;
}


th{
  border: 1px solid black;
  background-color: #abb3ac;
}

td{
  border: 1px solid black;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}

.coursesTable tr:last-child td{
  border:0px;
}

.pastParticTable tr:last-child td{
  border: 0px;
}

.numberData{
  text-align: right;
}

.test1{
  background-color: #679c80;
}

.Y{
  background-color: #679c80;
}

.gamesList {
  list-style: none;
  padding: 0px;
  width:80%;
  margin: 0 auto;
}

.gamesList h5{
  text-align: center;
}

.gamesList li{
  padding-top: 10px;
}

.buttonNav{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  background-color: rgb(235, 245, 242);
  width: 100%;
}

.detailsButton{
  width:150px;
  height:80px;
  margin: 15px;
  border-radius: 15px;
  background-color: #81b58b;
  cursor: pointer;
}

/* table
{
    counter-reset: rowNumber;
}

table tr > td:first-child
{
    counter-increment: rowNumber;
}

table tr td:first-child::before
{
    content: counter(rowNumber);
    min-width: 1em;
    margin-right: 0.5em;
} */

.mainContent{
  max-width: 80%;
  margin: 0 auto;
  background-color: rgb(235, 245, 242, 0.85);
  padding: 20px;
  height: 100%;
  min-height: 860px;
}

.eventList{
  list-style: none;
  padding: 0px;
  width:75%;
  margin: 0 auto;
}

.mainContent h1{
  text-align: center;
  font-size: 3em;
  margin: 0px;
  padding: 15px 0px 45px 0px;
}

.mainContent h4{
  text-align: center;
  margin-bottom:20px;
  line-height: 1.6em;
}

.mainContent h3{
  text-align: center;
}

.eventDiv{
  padding: 15px 10px 15px 10px;
  border-radius: 25px;
  border: 3px solid black;
  background-color: rgb(235, 245, 242);
  margin-bottom: 25px;
}

.inputDiv button{
  height: 100px;
  width: 200px;
  border-radius: 20px;
  font-size: 2em;
  background-color: rgb(36, 142, 199);
  color: white;
  border-color: rgb(14, 83, 120);
  margin: 0 auto;
}

.linkButton{
  height: 65px;
  width: 200px;
  border-radius: 20px;
  font-size: 2em;
  background-color: rgb(36, 142, 199);
  color: white;
  border-color: rgb(14, 83, 120);
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
}

.eventHeader{
  display:flex;
  justify-content: space-evenly;
}

.eventContent{
  display:flex;
  width:100%;
  justify-content: center;
  margin-bottom: 20px;
}

#playSignUp{
  height:20px;
  width:60%;
  max-width: 600px;
  margin: 0 auto;
  margin-top:20px;
  margin-bottom: 15px;
}

#signUpButton{
  height:26px;
  width: 20%;
  background-color: #b2ebcd;
  border-color: #679c80;
  border-radius: 15px;
  margin: 0 auto;
}

.eventContent section{
  width:40%;
  display: flex;
  flex-direction: column;
}

.attendeeDiv section{
  text-align: center;
  width: 100%;
}

.inputDiv{
  display: flex;
  flex-direction: column;
}

.attendeeInput{
  margin-bottom: 20px;
  height: 30px;
  border-radius: 12px;
  border: none;
  border-bottom: 2px solid black;
  background-color: #bbc9be;
  padding-left: 10px;
}

.attendeeInput::placeholder{
  color: #2d302d
}

.attendeeInfo{
  font-weight: 700;
}

.deleteUser{
  width:100px;
  height:50px;
}

.notesText{
  width:60%;
  margin: 0 auto;
  text-align: left;
}

.addEventdiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 60%;
  margin: 0 auto;
}

.addEventdiv button{
  width: 50%;
  height: 50px;
  border-radius: 10px;
  margin: 0 auto;
}

.deleteEvent {
  width: 20%;
  height: 100px;
  background-color: red;
  color: white;
  font-size: 1.2em;
  border-radius: 15px;
  margin: 0 auto;
}

.eventEdit{
  width: 200px;
  height: 60px;
  float: left;
  margin-bottom: 10px;
  border-radius: 15px;
  margin: 0 auto;
}

.editButton{
  border-radius: 15px;
  height: 70px;
  width: 100px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.notesDiv{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.addEvent{
  width: 100%;
  height: 40px;
  border-radius: 10px;
  margin: 10px;
}

.userList{
  list-style: none;
  width:100%;
}

.userList li{
  margin: 10px 0px 10px 0px;
  padding:5px;
  border: 1px solid black;
  border-radius: 15px;
}

.userList button {
  width:150px;
}

.accButtonDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accordionButton{
  width: 100%;
  height: 80px;
  border-radius: 15px;
  margin-bottom: 20px;
}

iframe{
  width:100%;
  height: 600px;
}

footer{
  background-color: #81b58b;
  height: 75px;
}

.footerContent{
  width: 85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.footerContent a {
  text-decoration: none;
}

.ogbText {
  font-size: 4em;
  text-align: center;
  color: white;
  text-shadow: 3px 3px 2px black;
}

#memberPic{
  width: 80%;
  display: block;
  margin: auto;
  margin-bottom: 15px;
}

.overviewText{
  width: 90%;
  margin: auto;
  line-height: 1.3em;
  margin-bottom: 20px;
}

.reqList{
  list-style:disc;
  width: 50%;
  margin: 0 auto;
}

.hofMembers{
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 10px;
  margin: 0 auto;
}

.hofMembers li{
  width: 350px;
}

.memberCard {
  border: 1px solid black;
  border-radius: 15px;
  text-align: center;
  background-color: white;
  margin: 2%;
  height: 550px;
}

.memberCard img{
  width: 300px;
  height: 300px;
  border-radius: 50%;
  padding: 15px;
  margin: 0 auto;
  display: block;
}

.yearDivider{
  background-color: white;
  border-radius: 25px;
  margin: 10px 5px 15px 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.yearButton{
  margin: 0 auto;
  width:100%;
  height:70px;
  border-radius: 25px;
  font-size: 2em;
}

.galleryDiv{
  display: flex;
  justify-content:space-around;
  flex-wrap: wrap;
}

.galleryDiv img {
  width:33%;
  padding:10px 0px 10px 0px;
  margin-bottom: 10px;
}

nav ul li{
  text-align: center;
}

.green{
  background-color: #679c80;
}

#Tie{
  background-color: transparent;
}

.dropdownContainer {
  text-align: left;
  border: 1px solid black;
  position: relative;
  border-radius: 15px;
  width:85%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding:20px;
  background-color: rgb(235, 245, 242);
  margin-bottom: 50px;
}

.dropdownInput {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  border:1px solid black;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.8);
  margin-bottom: 10px;
  width: 33%;
  height:30px;
  margin: 0 auto;
}

.scores{
  display: flex;
  flex-direction: column;
  width: 33%;
  padding: 0px 20px 0px 20px;
}

.indivScore{
  display: flex;
  margin: 4px;
  justify-content: space-between;
}

.indivScore input{
  margin-right: 25%;
}

.indivScore label{
  margin-left: 25%;
}

.dropdownContainer .inputDiv{
  width:33%;
}

.dropdownMenu {
  position: absolute;
  transform: translateY(4px);
  width: 33%;
  border: 1px solid black;
  border-radius: 15px;
  overflow: auto;
  max-height: 400px;
  background-color: white;
}

.dropdownItem {
  padding: 5px;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #bfbeba;
}

.dropdownItem.selected {
  background-color: #e37881;
  color: white;
}

.reviewTable thead{
  border-radius: 40px;
}

.revScore{
  text-align: right;
}

.reviewTable th{
  cursor: pointer;
}

.bannerMessage {
  height: 30px;
  color: white;
  font-size: 1.2em;
  background-color: #355e3b;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.marqueeDiv{
  margin: 0px;
  padding: 0px;
}

.coursesLi{
  width:20%;
}

.dropdownMenu{
  width:18%;
  border: 0px;
  background-color: rgb(114, 174, 218);
}

.ogbDropdown{
  background-color: rgba(128,168,139,0.9);
}

.dropdownMenu a{
  padding-bottom: 10px;
  cursor: pointer;
}

.dropdownMenu ul{
  list-style: none;
  display: flex;
  flex-direction: column;
}

.dropdownMenu ul li{
  font-size: 0.6em;
}

.timeSlotDiv{
  border: 4px solid #568c64;
  margin-bottom: 10px;
  width:30%;
  padding: 5px;
  border-radius: 20px;
  background-color: rgba(200, 222, 206, 0.6);
}

.timeSlotDiv li{
  list-style: none;
  font-size: 1em;
}

.timeSlotLI0{
  font-size: 1.2em;
  text-align: center;
}

.timeSlotList{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-left: 0px;
}

.timeSlotButton0{
  display:none;
}

.sfxButton{
  width:100px;
  height:80px;
  border-radius: 15px;
  font-size: 1.2em;
  background-color: rgb(36, 142, 199);
  color: white;
  border-color: rgb(14, 83, 120);
  margin: 0 auto;
}

#red{
  background-color: red;
}

.Empty{
  display: none;
}

.chevronButton{
  border: none;
  padding: 4px 0px 0px 3px;
  text-decoration: underline;
  cursor: pointer;
  color: #0000EE;
}

.signupContainer{
  border: 3px solid black;
  width: 90%;
  margin: 0 auto;
  border-radius: 30px;
  padding-top: 30px;
  margin-bottom: 40px;
  background-color: rgb(235, 245, 242)}


@media only screen and (max-width:1300px){
  .oglebayNav ul li{
    font-size: 1.1em;
  }

  .dropdownMenu a{
    font-size: 0.8em;
  }

  nav ul li{
    font-size: 2.5em;
  }
}

@media only screen and (max-width:1200px){
  nav ul li{
    font-size: 2.3em;
  }

  .dropdownMenu a{
    font-size: 1em;
  }

  .oglebayNav ul li{
    font-size: 2em;
  }

  nav ul{
    width:90%;
  }

  nav{
    height: 180px;
  }

  .navLogo{
    height:70px;
    width:130px;
  }

  .eventHeader h4{
    margin-right:10px;
    font-size: 0.8em;
  }

  .inputDiv button{
    width:70%;
    height:70px;
    font-size: 1.2em;
  }

  .linkButton{
    width:40%;
    height:60px;
    font-size: 1.6em;
  }

  .mainContent ul{
    width:80%;
  }

  .timeSlotList{
    width: 90%;
  }

  .hofMembers li{
    width: 300px;
  }

  .memberCard{
    height:450px;
  }
  .memberCard img{
    width:200px;
    height: 200px;
  }

  .detailsButton{
    width:120px;
    height:75px;
    margin:12px;
  }

  .indivScore input{
    margin-right: 15%;
  }
  
  .indivScore label{
    margin-left: 15%;
  }
}

@media only screen and (max-width:1050px){
  .oglebayNav ul li{
    font-size: 1.5em;
  }
  .hofMembers li{
    width: 250px;
  }

  .memberCard{
    height:400px;
  }

  .memberCard img{
    width:175px;
    height: 175px;
  }

  .detailsButton{
    width:110px;
    height:65px;
    margin:10px;
    font-size: 0.7em;
  }

  nav ul li{
    font-size: 1.8em;
  }

  .timeSlotDiv{
    width:40%;
  }
}

@media only screen and (max-width:880px){

  nav{
    height: 120px;
  }

  nav ul{
    padding-top:25px;
    padding-left: 0px;;
  }

  nav ul li{
    font-size: 1.75em;
  }

  .oglebayNav ul li{
    font-size: 1.3em;
  }

  footer{
    height:90px;
  }

  .eventContent h2{
    font-size: 1.2em;
  }

  .eventContent ol li{
    font-size: 0.8em;
  }

  table{
    width:85%;
  }

  .detailsButton{
    width:90px;
    height:55px;
    margin:5px;
    font-size: 0.6em;
  }

  .navLogo{
    height:60px;
    width:80px;
  }

  .indivScore input{
    margin-right: 0%;
  }
  
  .indivScore label{
    margin-left: 0%;
  }
}

@media only screen and (max-width: 800px){
  .hofMembers li{
    width: 200px;
  }

  .memberCard{
    height:375px;
  }
  .memberCard img{
    width:150px;
    height: 150px;
  }

  nav ul li{
    font-size: 1.65em;
  }
}

@media only screen and (max-width: 760px){
  .oglebayNav ul li{
    font-size: 1.15em;
  }

  nav ul{
    width:95%;
  }

  nav ul li{
    font-size: 1.3em;
  }

  .oglebayNav ul{
    padding-top: 15px;
  }

  th{
    font-size: 0.9em;
  }

  td{
    font-size: 0.8em;
  }

  .detailsButton{
    width: 85px;
  }

  .timeSlotDiv{
    width: 85%;
  }
}

@media only screen and (max-width:630px){
  nav{
    height: 110px;
  }

  .navLogo{
    height:50px;
    width: 60px;
  }
  
  nav ul li{
    font-size: 1.05em;
  }

  .oglebayNav ul li{
    font-size: 0.9em;
  }

  .hpContent{
    width:80%;
  }

    .hpContent p{
    font-size: 0.8em;
    line-height: 1.4em;
  }

  .hpContent li{
    font-size: 0.8em;
    line-height: 1.4em;
  }

  .attendeeInput{
    width:75%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  th{
    font-size: 0.8em;
  }

  td{
    font-size: 0.7em;
  }

  .dropdownContainer{
    flex-direction: column;
  }

  .dropdownInput{
    width:60%;
    margin-bottom: 20px;
  }

  .scores{
    width:60%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .dropdownContainer .inputDiv{
    width:60%;
    margin: 0 auto;
  }

  .linkButton{
    width:60%;
    height:60px;
    font-size: 1.3em;
  }
  /* .dropdownContainer{
    width:90%;
  }

  .dropdownContainer .inputDiv button{
    font-size: 0.8em;
  }

  .dropdownContainer .inputDiv .attendeeInput{
    font-size: 0.6em;
  }

  .dropdown-selected-value {
    font-size: 0.8em;
  } */

}

@media only screen and (max-width: 550px){
  
  th{
    font-size: 0.7em;
  }
  
  td{
    font-size: 0.6em;
  }

  nav ul li{
    font-size: 0.9em;
  }
}

@media only screen and (max-width:480px){
  nav{
    height:100px;
  }

  .navLogo{
    height:45px;
    width:55px;
    margin-top: -10px;
  }
  
  nav ul li{
    font-size: 0.65em;
  }

  .oglebayNav ul li{
    font-size: 0.8em;
  }

  .golfPTable th{
    font-size: 0.6em;
  }

  .mainContent{
    max-width:90%;
  }

  .hpContent p{
    font-size: 0.8em;
  }

  footer{
    height:110px;
  }

  .eventContent h2{
    font-size: 1em;
  }

  .eventContent ol{
    padding-left: 20px;
  }

  .eventContent ol li{
    font-size: 0.7em;
  }

  .detailsButton{
    width:85px;
    height:65px;
    margin:8px;
  }

  .coursesTable td{
    font-size: 0.55em;
  }

  .coursesTable{
    margin-left: 0px;
    width:95%;
  }

  .reviewTable{
    margin-left: 0px;
    font-size: 0.85em;
  }

  .sfxButton{
    width: 80px;
    height: 70px;
    font-size: 0.8em;
  }
}